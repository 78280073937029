.cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.cardIcons {
    margin-right: 5px;
}

.cardTags{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    margin: 10px 0;
}

.cardTags .tag{
    background-color: #F8F9FC;
    padding: 2px 8px;
    border-radius: 20px;
    font-size: 12px;
}

.MediaContainer{
    position: relative;
}

.MediaContainer .liked{
    position: absolute;
    right:20px;
    top: 20px;
}