.customtab_steper { 
    padding: 80px 0;
  max-width: 900px;
  margin: 0 auto;
} 
.steper_main_edit_new nav.EditListingWizard_nav__1fa79 {
  display: none;
}

.main_title_page {
  flex: 0 0 100%;
  max-width: 100%;
}

.main_title {
  color: #000;
}

.steper_main_edit_new .MuiBox-root.MuiBox-root-1 .MuiBox-root.MuiBox-root-2 {
  color: #000;
}

.listing_title_head {
  font-weight: 600;
  margin-bottom: 25px;
}

.list_points {
  max-width: 500px;
  margin: 0 auto;
}

.mainHeading {
  color: #000;
  line-height: normal;
}

.mainHeading1 {
  color: #000;
  line-height: normal;
  margin-bottom: 30px;
}

.eachlisting {
  color: #000;
  line-height: normal;
  margin-bottom: 5px;
}

.list_points li {
  list-style: none;
  color: #000;
}

.select_listing_type button {
  max-width: 170px;
  border-radius: 5px;
  margin: 40px auto 0;
  font-size: 18px;
}

.customtab_steper .steper_main_edit_new {
  width: 100%;
  max-width: 100%;
}

.customtab_steper .main_title_page h3 {
  margin-bottom: 30px;
  font-weight: 600;
}

.customtab_steper button {
  max-width: 170px;
  border-radius: 5px;
  margin: 30px auto 0;
}

.customtab_steper .steper_main_edit_new {
  display: block;
}

.customtab_steper .MuiBox-root h1 {
  text-align: center;
  color: #000;
  font-weight: 600;
}

.customtab_steper .MuiBox-root .button_collection button {
  max-width: 250px;
}

.customtab_steper .check_icon svg {
  fill: #3f53b5;
  width: 60px;
  height: 60px;
}

.customtab_steper .EditListingWizard_panel__1Le1W {
  border: 0;
  padding-left: 0;
}

@media (max-width: 1023px) {
  .steper_main_edit_new nav.EditListingWizard_nav__1fa79 {
    padding: 040px 30px 40px 30px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 50px;
  }
}

@media (max-width: 992px) {
  .steper_main_edit_new {
    max-width: calc(100% - 30px);
    margin: 0 auto;
  }

  .customtab_steper {
    padding: 20px 15px 30px;
  }
}

@media (max-width: 767px) {
  .steper_main_edit_new nav.EditListingWizard_nav__1fa79 {
    padding: 20px 15px;
    flex-direction: column;
  }

  .steper_main_edit_new nav.EditListingWizard_nav__1fa79 .EditListingWizard_tab__puo7f {
    padding: 0;
    margin: 0;
  }

  .steper_main_edit_new .MuiBox-root.MuiBox-root-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .steper_main_edit_new .MuiBox-root.MuiBox-root-1 .MuiBox-root.MuiBox-root-2 h3 {
    margin-bottom: 15px;
    font-size: 35px;
  }

  .steper_main_edit_new .MuiBox-root.MuiBox-root-1 .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal .MuiStep-root.MuiStep-horizontal {
    padding: 0;
  }

  .steper_main_edit_new .MuiBox-root.MuiBox-root-1 button.Button_root__2bb-J {
    font-size: 14px;
    line-height: normal;
  }

  .steper_main_edit_new .MuiBox-root.MuiBox-root-1 .MuiBox-root.MuiBox-root-3 h1 {
    font-size: 30px;
  }

  .steper_main_edit_new .MuiBox-root.MuiBox-root-1 .MuiPaper-root.MuiStepper-root.MuiStepper-horizontal span.MuiStepConnector-line {
    margin-left: -8px;
  }

  .steper_main_edit_new nav.EditListingWizard_nav__1fa79 {
    margin-bottom: 30px;
  }
}

/* 05/05/2022 */

.button_collection>div {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.search-wrapper.searchWrapper input {
  height: 44px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-weight: 500;
  text-align: left;
}

h1.music_style_heading {
  font-size: 30px;
}

.customtab_steper .select_music_styles.edit_music_style h1 {
  font-size: 26px;
}

.select_music_styles.edit_music_style {
  margin-top: 100px;
}
/* 08-06-2022 */
.EditTabcointainer.customtab_steper {
  width: 100%;
  max-width: 1170px;
  padding: 0 15px;
}
.EditTabcointainer [class*="EditListingWizard_tabsContainer"] {
  padding-left: 0;
  width: 100%;
}
.EditTabcointainer .Tabs_Custom {
  max-width: 100%;
  flex: 100%;
  padding-left: 55px;
}
.EditTabcointainer.customtab_steper form {
  background: #f1f1f1;
  padding: 30px 50px; 
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;border-radius: 10px;
}
.EditTabcointainer.customtab_steper .search-wrapper.searchWrapper {
  padding: 0;
}
.EditTabcointainer .d_flex_main {
  max-width: calc(100% - 200px);
  flex: 100%;
}
.EditTabcointainer.customtab_steper form button {
  max-width: 180px;
  flex: 100%;
  width: 100%;margin-right: 0;
  
}
.EditTabcointainer.customtab_steper form .Select_Services {
  font-size: 20px;
  color: #000;
}
@media (max-width: 991px) {
  h1.music_style_heading {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .steper_main_edit_new .MuiBox-root.MuiBox-root-1 .MuiBox-root.MuiBox-root-3 h1 {
    font-size: 24px;
  }

  .customtab_steper button {
    max-width: 170px;
    border-radius: 5px;
    margin: 15px auto 10px;
  }

  .select_music_styles.edit_music_style {
    margin-top: 10px;
  }
  .EditTabcointainer.customtab_steper .search-wrapper.searchWrapper{
    margin-bottom: 0 !important;
  }
}
