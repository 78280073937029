.FormRow {
  justify-content: center;
  /* max-width: 100px; */
  border-radius: 5px;
}
.selected {
  color: black;
}
li.active_list.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  background: green;
}
.FieldWraper button {
  font-size: 30px;
  color: #3569fe;
  margin: auto;
}
.weekdays {
  flex: 1 1;
  width: 100%;
  max-width: 210px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.weekdays input {
  width: auto;
}
.mobileWeekdays {
  flex: 1;
  width: 100%;
  max-width: 90px;
}
.EditListingAvailabilityPlanForm_week__1V0_f {
  background: #f1f1f1;
}
.TimepickerCustom {
  max-width: calc(100% - 90px);
}
.WeeekMAin > div {
  padding: 15px 30px;
  background: #f1f1f1;
  align-items: center;
}

.TimepickerCustom > div::before {
  display: none;
}
.FieldWraper {
  padding: 0;
}
.DashBetweenTimesCustom {
  padding-bottom: 0;
}
.addmorebutton button:hover {
  text-decoration: none;
}

.WeekDaysMain.desktop_custom_avalibility_date .TimepickerCustom {
  max-width: calc(100% - 210px);
  width: 100%;
}

.undefined.FieldCustom,
.undefined.FieldsCustomdiv {
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 8px;
}
div svg.MuiSvgIcon-root.DeleteICon {
  fill: #ef6a6a;
}
.addmorebutton {
  display: flex;
  align-items: center;
}
.FieldWraper {
  padding: 0;
  gap: 15px;
}
.WeeekMAin select:disabled {
  background: #ececec;
}
.mobileWeekdays label {
  margin-right: 0;
}
@media (max-width: 767px) {
  .weekdays {
    flex: 60px 1;
    width: 100%;
    max-width: 60px;
  }
  .weekdays span {
    font-size: 14px;
  }
  .WeeekMAin > div {
    padding: 15px 15px;
  }
  .TimepickerCustom {
    max-width: calc(100% - 60px);
  }

  .undefined.FieldCustom,
  .undefined.FieldsCustomdiv {
    max-width: 100px;
    flex: 100%;
  }
  .FieldWraper {
    gap: 10px;
  }

  .MuiDialog-scrollPaper div.MuiDialog-paperScrollPaper {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
  .MuiDialog-scrollPaper div.MuiDialog-paperScrollPaper .MuiTabs-fixed button.MuiButtonBase-root {
    max-width: 100%;
    width: 100%;
    flex: 100%;
  }
  .MuiDialog-scrollPaper .MuiDialogContent-root:first-child {
    padding: 0;
  }
  .MuiTabs-centered button:not(.Mui-selected) {
    background: #b7b7b7;
  }
  .MuiTabs-centered button:not(.Mui-selected) span {
    color: #595959;
  }
  .MuiTabs-indicator {
    opacity: 0;
  }
  ul.starttime_ul,
  ul.endtime_ul {
    height: 70px;
    overflow-y: auto;
    position: relative;
  }
  /* :where(ul.starttime_ul,ul.endtime_ul):before,:where(ul.starttime_ul,ul.endtime_ul):after {
  content: "";
  left: 0;
  top: 0;
  background: rgb(255 255 255 / 95%);
  width: 100%;
  height: 20px;
  position: absolute;
  z-index: 9;
  filter: blur(10px);
} */
  /* :where(ul.starttime_ul,ul.endtime_ul):after {
  top: auto;
  bottom: 0;
  height: 20px;
} */
  .MuiDialogActions-spacing {
    flex-wrap: wrap;
  }

  .MuiDialogActions-spacing button.MuiButtonBase-root {
    width: 100%;
    flex: 100%;
    margin: 0;
  }
  .MuiButton-text.save {
    background: #3f51b5;
    color: #fff;
    border-radius: 30px;
  }
  :where(ul.starttime_ul, ul.endtime_ul) li.MuiButtonBase-root {
    line-height: normal;
    height: auto;
    min-height: auto;
  }
  :where(ul.starttime_ul, ul.endtime_ul)
    li.active_list.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    background: #3f53b5ba;
    color: #fff;
  }
  .MuiDialog-root[role='presentation'] {
    bottom: 0;
    top: auto !important;
  }
  .undefined.FieldCustom,
  .undefined.FieldsCustomdiv {
    border: none;
    background: none;
    border-radius: unset;
  }
  .FormRow {
    max-width: 190px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #fff;
    flex: 100%;
  }
}

/* @media (max-width:578px) */
