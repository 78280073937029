.root {
}

.input {
  /* border-color: var(--attentionColor); */
  /* padding: 10px 14px; */
  padding:10px 14px;
  box-sizing: border-box;

}

.inputSuccess {
  /* border-color: var(--successColor); */
}

.inputError {
  /* border-color: var(--failColor); */
}

