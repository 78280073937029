nav>a>svg {
    margin-right: 5px;
}

/* 26 May */

a.NamedLink_active+a:empty {
    display: none;
}

.Topbar_container__1oZu7 {
    justify-content: center;
}