@import '../../styles/propertySets.css';

.setionContainer {
  background-color: red;
}

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}
/* .phone {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
} */
.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 56px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 24px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 44px;
    margin-bottom: 20px;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;
  background-image:  url("data:image/svg+xml,%3Csvg width='60' height='68' viewBox='0 0 60 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M56.6667 64C56.6667 59.3481 56.6667 57.0222 56.0926 55.1295C54.7999 50.8682 51.4652 47.5334 47.2038 46.2408C45.3112 45.6666 42.9852 45.6666 38.3334 45.6666H21.6667C17.0148 45.6666 14.6889 45.6666 12.7962 46.2408C8.53489 47.5334 5.20017 50.8682 3.90751 55.1295C3.33337 57.0222 3.33337 59.3481 3.33337 64M45 19C45 27.2843 38.2843 34 30 34C21.7158 34 15 27.2843 15 19C15 10.7157 21.7158 4 30 4C38.2843 4 45 10.7157 45 19Z' stroke='%23475467' stroke-width='6.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-position: center;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: solid;
  border-color: var(--profileAvartarBorderColor);
  border-width: 4px;
  transition: var(--transitionStyleButton);
  /* &:hover { */
    /* border-color: var(--matterColorAnti); */
  /* } */
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 10px;
  right: 2px;
  /* Dimensions */
  width: 24px;
  height: 24px;
  /* padding: 11px 10px 7px 35px; */
  border-radius: 16px;


  


  /* Look and feel (buttonish) */
  background-color: var(--avatarChangeColor);
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 1.5V8.5M1.5 5H8.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 7px 7px;
  /* border: solid 1px var(--matterColorNegative); */

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -3px;
    right: 5px;
    margin-top: 0;
    margin-bottom: 10px;
    transition: var(--transitionStyleButton);
  }

  /* &:hover { */
    /* border: solid 1px var(--matterColorAnti); */
  /* } */
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
}
.check_circle {
  font-size: 8rem !important;
  color: #8c52ff;
}
.all_done_container {
  text-align: center;
}
.all_done {
  font-size: 30px;
}
.actionbuttons {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
.button_css {
  width: 100%;
}
.heading_of_profile {
  text-align: center;
  font-size: 30px;
  margin-top: 0;
}
.formControl {
  width: 100%;
}

.MuiBox-root.MuiBox-root-1 .PhoneInput .PhoneInputCountrySelectArrow {
width: 8px !important;
height: 8px !important;
margin: 0 10px !important

}

.MuiSwitch-switchBase.Mui-checked .MuiSwitch-track {
  background-color: #7f56d9 !important;
}


.stripeContainerInfo{
  border-radius: 8px;
  border: 1px solid #D6BBFB;
  background-color: #FCFAFF;
  padding: 16px;
  display: flex;
  gap: 10px;

}

.stripeContainerInfo p{
  margin: 0;
  font-size: 14px;
  color: #7F56D9;
  line-height: 20px;
}

.stripeHeading {
  font-weight: bold;
  color: #6941C6;
  margin-bottom: 5px !important;
}