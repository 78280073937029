
.call_class a {
    background: #404040;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 50px;
    justify-content: center;
    max-width: 80%;
    font-size: 20px;
    color: #25aeec;
    border-radius: 10px;
    border: 1px solid #2292c1;
    text-decoration: none !important;
    margin: 0 auto;
}

.call_class span.cancel_class {
    background: #404040;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 50px;
    justify-content: center;
    max-width: 80%;
    font-size: 20px;
    color: #25aeec;
    border-radius: 10px;
    border: 1px solid #2292c1;
    margin: 0 auto;
}
.call-button {
    max-width: 160px;
    width: 100%;
}

.call-button:focus {
    border: 2px solid #774ea2 !important;
}

.call-button svg {
    width: 15px;
    margin-right: 13px;
}


.ProfileSettingsPage_content__11Fax {
    width: 100%;
}


.ProfileSettingsPage_content__11Fax svg.MuiSvgIcon-root text {
    font-size: 0;
}
.ProfileSettingsForm_heading_of_profile__S4DzM {
    color: #000;
    margin: 0;
} 
.ProfileSettingsPage_content__11Fax form.ProfileSettingsForm_root__1V6WW {
    margin: 0;
}
.ProfileSettingsPage_content__11Fax .multiselect-container {
    box-shadow: 0px 7px 5px #dddddd52;
}
.ProfileSettingsPage_content__11Fax .multiselect-container .search-wrapper.searchWrapper {
    border: 0;
} 
.ProfileSettingsPage_content__11Fax .ProfileSettingsForm_actionbuttons__QuiRG {
    margin-top: 80px;
}
.ProfileSettingsPage_content__11Fax form.ProfileSettingsForm_root__1V6WW .ProfileSettingsForm_sectionContainer__DiBbb {
    padding-top: 35px;
   
}
.ProfileSettingsPage_content__11Fax form.ProfileSettingsForm_root__1V6WW .ProfileSettingsForm_sectionContainer__DiBbb .ProfileSettingsForm_uploadAvatarWrapper__12Yqu label.ProfileSettingsForm_label__3WgPV {
    margin: 0 auto;
}
.ProfileSettingsPage_content__11Fax  .ProfileSettingsForm_nameContainer__15irm label {
    text-align: initial;
}
.ProfileSettingsPage_content__11Fax .ProfileSettingsForm_actionbuttons__QuiRG 
 button {
    border-radius: 8px;
}
.sectionContainer{
    background:red;
}
.ProfileSettingsForm_sectionContainer__DiBbb1 {
    text-align: center;
    /* padding-top: 35px; */
}
.ProfileSettingsForm_sectionContainer__DiBbb1 label.ProfileSettingsForm_label__3WgPV {
    margin: 0 auto;
}
@media(max-width:1023px){
.call-button {
    margin-right: 23px !important;
}
}

@media(max-width:767px){
.ProfileSettingsForm_heading_of_profile__S4DzM {
    font-size: 25px;
}
.ProfileSettingsPage_content__11Fax form.ProfileSettingsForm_root__1V6WW .ProfileSettingsForm_sectionContainer__DiBbb {
    padding-top: 20px;
    margin-bottom: 30px;
} 
.ProfileSettingsPage_content__11Fax .ProfileSettingsForm_actionbuttons__QuiRG {
    margin-top: 50px;
}
.ProfileSettingsForm_button_css__3bEor {
   
    min-height: 45px;
}
.ProfileSettingsForm_sectionContainer__DiBbb1 {
    padding-top: 15px;
}
}



