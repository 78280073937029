.main_container {
  display: flex;
}

.main_container .button_container {
  display: flex;
  /* margin-left: 15px; */
}

.main_container .form_container select {
  margin-left: 0;
}

.main_container .button_container .searchbox {
  width: 100%;
}

.d_flex {
  display: flex;
  /* justify-content: space-between !important;
    width: 100%; */
}

.main_container .button_container .searchbox button {
  max-width: 340px;
  background: #fff;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 20px;
  width: 100%;
  display: flex;
  height: 72px;
  align-items: center;
  color: #ccc;
}

.main_container .button_container .searchbox > div {
  width: 100%;
  max-width: 100%;
}

.main_container .button_container .searchbox > div .FilterPopup_root__1Xfkn {
  width: 100%;
}

.MuiInput-underline::before {
  border: unset !important;
  padding: 7px 7px !important;
}

.MuiGrid-container {
  padding: 7px;
  background: #fff;
  border-radius: 6px;
}

#date-picker-inline {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .w_45 {
    width: 45%;
  }

  .main_container {
    display: flex;
    margin-top: 40px;
  }
}

.w_100 {
  width: 100%;
}

/* 5-05-2022 */

.button_container form :where(select, input),
.main_homepage_section .topbar_search_home select {
  color: #000;
  font-weight: 400;
  opacity: 1;
  font-family: poppins;
  font-size: 16px;
  padding: 0 0 0 18px;
}

.button_container form :where(select, input)::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000;
  font-weight: 400;
  opacity: 1;
  /* Firefox */
}

.button_container form :where(select, input):-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
  font-weight: 400;
}

.button_container form:where(select, input)::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000;
  font-weight: 400;
}

.search-wrapper.searchWrapper input {
  text-align: left;
}

div #date-picker-inline {
  font-family: poppins;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
  color: #98A2B3;
}

div #date-picker-inline::placeholder {
  color: #98A2B3;
}

.custom_home_ser div input::placeholder{
  color: #98A2B3 !important;
}

.MuiGrid-justify-content-xs-space-around {
  height: 100%;
  align-items: center;
}

.custom_home_ser {
  /* overflow: hidden; */
  border-radius: 5px;
}

.custom_home_ser .search-wrapper.searchWrapper input {
  height: auto;
  border: none;
}

.d_flex .MuiInputAdornment-root.MuiInputAdornment-positionEnd,
.topbar_search_heroContainer .main_container .button_container form > * svg {
  opacity: 0;
}

@media (min-width: 992px) {
  .topbar_search_hero .MuiGrid-justify-content-xs-space-around,
  .topbar_search_hero .main_homepage_section .MuiBox-root select,
  .topbar_search_hero div.custom_home_ser div#multiselectContainerReact {
    top: 0;
    border: none;
    height: 100%;
  }

  .topbar_search_hero .d_flex > div {
    max-width: calc(100% / 2 - 10px);
    flex: calc(100% / 2 - 10px);
    width: 100%;
  }

  .topbar_search_hero .MuiInput-underline:after {
    display: none;
  }

  .topbar_search_heroContainer .main_container .button_container form > * {
    max-width: calc(100% / 3);
    flex: calc(100% / 3);
    width: 100%;
  }

  .topbar_search_heroContainer .main_container .button_container form {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }

  .topbar_search_heroContainer .main_container .button_container {
    width: 100%;
    border:1px solid #d0d5dd;
  }
}

@media (max-width: 767px) {
  div #date-picker-inline {
    font-size: 16px;
    padding-bottom: 0;
  }

  .main_homepage_section .topbar_search_home select {
    height: 55px;
  }

  .main_homepage_section {
    margin-bottom: 13px;
  }

  .topbar_search_heroContainer .main_container .button_container form > * {
    /* flex: 100%; */
    height: auto;
    width: 90%;
    margin: auto;
  }

  .topbar_search_heroContainer .main_container .button_container form {
    gap: 18px;
  }

  .topbar_search_heroContainer .main_container .button_container form > * div,
  .topbar_search_heroContainer .main_container .button_container form .main_homepage_section {
    margin: 0;
  }

  .topbar_search_heroContainer .main_container .button_container form > * div {
    justify-content: flex-start;
  }

  .topbar_search_hero .MuiGrid-justify-content-xs-space-around,
  .topbar_search_hero .main_homepage_section .MuiBox-root select,
  .topbar_search_hero div.custom_home_ser div#multiselectContainerReact {
    top: 0;
    border: none;
    height: 55px;
  }

  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    border: none;
  }

  /* // 26May */

  .BottomNavigation_class {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    right: 0;
    border-top: 1px solid #ddd;
    justify-content: space-between;
  }

  .BottomNavigation_class button{
    min-width: auto;
  }
  /* .MuiBottomNavigationAction-root.Mui-selected {
    background: #3f51b5;
    color: #fff;
} */
}
