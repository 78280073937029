/* 11-06-2022 */
.field_textfield input[type="text"] {
    width: 100px;
    flex: 100%;
    max-width: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
  }
  
  .field_textfield>span{
    max-width: calc(100% - 100px);
    flex: 100%;
    width: 100%;
  }
  
  
  .field_textfield{
    background: #fff;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px; 
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .field_textfield span {
    font-size: 18px;
    color: #000;
}
.disable_other_button .field_textfield span{ color: #8c52fe;}
.field_textfield svg {
    transform: scale(1.2);
}