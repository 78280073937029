.headerSearch {
  height: 80%;
  margin: auto;
  width: 60%;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  max-width: 900px;
}

.headerSearch .search-redesign {
  background-color: transparent;
}

.headerSearch .main_container {
  height: 100%;
}

.headerSearch .selectBox,
.headerSearch .selectBox > div,
.headerSearch .selectBox .topbar_search_home,
.headerSearch .cityContainer,
.headerSearch .cityField {
  height: 100%;
}

.topbar_search_hero .cityField input::placeholder,
.topbar_search_hero .dateField input::placeholder,
.topbar_search_hero .selectField {
  color: #4a4a4a !important;
}

.headerSearch .cityField input::placeholder,
.headerSearch .dateField input::placeholder,
.headerSearch .selectField {
  color: #4a4a4a !important;
}

.cityField .icon_down_dir{
  display: none !important;
}

.topbar_search_hero .dateField input {
  margin-top: -5px;
}

.topbar_search_hero svg {
  stroke: #4a4a4a !important;
}

.headerSearch svg {
  stroke: #4a4a4a !important;
}

div #date-picker-inline {
  padding-bottom: 0;
}

.headerSearch .main_container .selectField {
  height: 100%;
  padding-left: 15px;
  border: none;
  width: 150px;
  border: none;
  border-right: 1px solid #d0d5dd;
  border-radius: 0;
}

.headerSearch .main_container .dateContainer {
  border: none;
  border-right: 1px solid #d0d5dd;
  border-radius: 0;
}

.headerSearch .main_container .dateField {
  margin: 0;
}

.headerSearch .main_container .dateField button {
  display: none;
}

.headerSearch button {
  min-height: 100%;
  font-size: 14px;
  border-radius: 10px;
  width: 200px;
}

.menu-drop-container {
  display: flex;
}

.menu-drop-container svg {
  fill: transparent;
  margin-right: 10px;
}

.menu-drop-container p {
  margin: 0;
  margin-top: -5px;
  color: #101828;
}

.menu-drop-container .desc {
  color: #667085;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .headerSearch .main_container .selectField {
    width: 100px;
    font-size: 14px;
  }
  .headerSearch .selectBox,
  .headerSearch .selectBox > div,
  .headerSearch .selectBox .topbar_search_home,
  .headerSearch .cityContainer,
  .headerSearch .cityField {
    margin-left: 0;
  }

  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    min-width: 0;
    width: auto;
  }

  .cityContainer svg {
    margin-left: 10px;
  }
  #search_input,
  div #date-picker-inline {
    font-size: 14px;
  }
  .headerSearch button {
    width: 160px;
  }
  .topbar_search_hero h1 {
    max-width: 100%;
    font-size: 48px;
  }
  .singleCardMAxWidth {
    max-width: 300px !important;
  }
}

div.custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
  padding-left: 15px !important;
}