*{
    font-family: "poppins";
}
.musicstyle_button {
    display: flex;
    flex-wrap: wrap;
}

.music_btn_name_listing_card {
    background: #eee6ff;
    margin-right: 12px;
    border: 1px solid #eee6ff;
    border-radius: 20px;
    color: #651af9;
    padding: 0 20px;
    width: auto;
    height: 50%;
    margin-bottom: 5px;
    font-size: 13px;
    font-family: 'poppins';
}
.custom_main {
    flex-wrap: wrap;
}

@media (max-width:767px) {
    .music_btn_name_listing_card {
        background: rgb(140, 82, 255);
        margin-right: 4px;
        border: 1px solid #8c52ff;
        border-radius: 20px;
        color: #fff;
        padding: 4px 9px 4px 5px;
        height: auto;
        margin-bottom: 5px;
        font-size: 12px;
        display: inline-flex;
        line-height: 1.5;
    }

    .music_btn_name_listing_card svg {
        margin-right: 5px;
        width: 16px;
    }
}