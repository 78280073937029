.MuiBox-root {
  counter-reset: count;
}

.ques {
  counter-increment: count;
  font-size: 18px;
}

.ques:before {
  content: counter(count, upper-alpha) ')';
  margin-right: 10px;
  color: #906efa;
}

.ques + span.answer {
  font-size: 16px;
  padding-left: 30px;
}

.ques:not(:first-child) {
  margin-top: 10px;
}

textarea.SendMessageForm_textarea__F3g9- {
  border: 1px solid #f3f3f3;
  padding: 10px;
  margin-top: 0;
}

.ActivityFeed_transition__TB6Pf {
  margin-bottom: 0;
  background: #fff;
  padding: 10px;
}

@media (max-width: 991px) {
  .LayoutSingleColumn_layoutWrapperMain__zOvja .TransactionPanel_headingSale__ee7Sr {
    max-width: 100%;
  }

  .TransactionPanel_headingSale__ee7Sr span {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .MuiBox-root.MuiBox-root-4 {
    padding: 5px 10px 0 10px;
  }
}

/* 25 may 2022 */

.TransactionPanel_txInfo__1fiSI.tranaction_messae_inner {
  margin-top: 30px;
}

.TransactionPanel_txInfo__1fiSI.tranaction_messae_inner button.open_details_class {
  background: #8c52ff;
  border-radius: 5px;
  color: #fff;
  border: none;
  padding: 4px 6px;
}

.booking_details_mobile button {
  margin-bottom: 20px;

}
.booking_details_mobile>svg {
  position: absolute;
  top: 0;
  right: 0;
}

.booking_details_mobile {
  position: relative;
  padding-top: 40px;
}
.ShowButton_LIST {
  /* text-align: center; */
  display: flex;
  justify-content: space-between;
}
@media (max-width:991px){

.submit_container_cls button  {
  padding: 10px 10px;
  width: 50px;
}

.submit_container_cls a {
  display: inline-block;
}

.call-button svg {
  width: 20px;
}

.submit_container_cls {
  gap: 10px;width: 120px;
}
div .submit_container_cls :where(button,a) {
  flex: 100%;
  max-width: 100%;
  width: 100%;
  min-width: 55px;
}
.submit_container_cls button {
  padding: 10px 10px;
  width: 50px;
  min-height: 50px;
  aspect-ratio: 1/1;
}
}