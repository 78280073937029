.headerSearch {
  height: 80%;
  margin: auto;
  width: 60%;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  max-width: 900px;
}

.headerSearch .search-redesign {
  background-color: transparent;
}

.headerSearch .main_container {
  height: 100%;
}

.headerSearch .selectBox,
.headerSearch .selectBox > div,
.headerSearch .selectBox .topbar_search_home,
.headerSearch .cityContainer,
.headerSearch .cityField {
  height: 100% !important;
}

div #date-picker-inline {
  padding-bottom: 0;
}

.headerSearch .main_container .selectField {
  height: 100%;
  padding: 0;
  padding-left: 15px;
  border: none;
  width: 150px;
  border: none;
  border-right: 1px solid #d0d5dd;
  border-radius: 0;
}

.headerSearch .main_container .dateContainer {
  border: none;
  border-right: 1px solid #d0d5dd;
  border-radius: 0;
}

.headerSearch .main_container .dateField {
  margin: 0;
}

.headerSearch .main_container .dateField button {
  display: none;
}

.headerSearch button {
  min-height: 100%;
  font-size: 14px;
  border-radius: 10px;
  width: 200px;
}

.menu-drop-container {
  display: flex;
}

.menu-drop-container svg {
  fill: transparent;
  margin-right: 10px;
}

.menu-drop-container p {
  margin: 0;
  margin-top: -5px;
  color: #101828;
}

.menu-drop-container .desc {
  color: #667085;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .headerSearch .main_container .selectField {
    width: 100px;
    font-size: 14px;
  }
  .headerSearch .selectBox,
  .headerSearch .selectBox > div,
  .headerSearch .selectBox .topbar_search_home,
  .headerSearch .cityContainer,
  .headerSearch .cityField {
    margin-left: 0;
  }

  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    min-width: 0;
    width: auto;
  }

  .cityContainer svg {
    margin-left: 10px;
  }
  #search_input,
  div #date-picker-inline {
    font-size: 14px;
  }
  .headerSearch button {
    width: 160px;
  }
  .topbar_search_hero h1 {
    max-width: 100%;
    font-size: 48px;
  }
  .singleCardMAxWidth {
    max-width: 300px !important;
  }
}

@media (max-width: 767px) {
  .main_container .button_container {
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
  }

  .topbar_search_hero h1 {
    line-height: 1.2;
    font-size: 32px;
  }
}

.TopbarDesktop_root__1JhZ0 .contactus {
  color: black;
}

.search-redesign {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
}

#search_input {
  margin: 0;
}

.searchPage .viewAllLink {
  display: none;
}

.singleCardContainer .cardContainerSingle {
  margin-right: 1%;
  margin-bottom: 10px;
  width: 24%;
}

@media (max-width: 600px) {
  .singleCardContainer .cardContainerSingle {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100% !important;
  }
  .main_container .button_container svg {
    position: static;
    transform: translateY(0);
    margin-left: 0 !important;
  }

  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    border: none;
  }
}

.singleCardContainer .cardContainerSingle:last-child {
  margin-right: 0;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: none !important;
  border: none !important;
}

.rc-slider-rail {
  background-color: white !important;
  opacity: 0.5;
}

.rc-slider-track {
  background-color: white !important;
}

label.label {
  color: #344054;
  font-size: 14px;
  margin-bottom: 6px;
}

.customField {
  background-color: transparent;
  width: 100%;
  padding: 10px 14px !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  margin-bottom: 20px !important;
}

select.customField {
  background-position: center right 10px !important;
}

.quill {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  margin-bottom: 20px;
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-container {
  min-height: 120px !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #8c52ff !important;
}

button {
  cursor: pointer !important;
}

.react-calendar button {
  color: #344054 !important;
  font-size: 14px;
}

button.react-calendar__month-view__days__day--neighboringMonth {
  color: #667085 !important;
}

.react-calendar abbr {
  text-decoration: none !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active:not(:disabled)  {
  background-color: transparent !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 28px;
  color: #667085 !important;
}

.react-calendar__navigation__label {
  font-size: 16px !important;
}

/* .react-calendar .react-calendar__tile--now, */
/* button.react-calendar__tile--active,.selected-date {
  border-radius: 10px;
  background: #8c53ff !important;
  color: white !important;
} */

.react-calendar__tile.selected-date:not(:disabled) {
  border-radius: 10px;
  background: #8c53ff !important;
  color: white !important;
}
.react-calendar__tile:disabled {
  position: relative;
}
.react-calendar__tile:disabled::after {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus,
.react-calendar__tile--active:not(:disabled) {
  border-radius: 10px;
  background: #8c53ff !important;
  color: white !important;
} */

.customField.reherse {
  margin-bottom: 5px !important;
}

.calendarContainer {
  position: absolute;
  left: 0;
}

.mainField .field_textfield {
  flex-wrap: nowrap !important;
}

.mainField .field_textfield > span {
  width: auto;
  flex: auto;
  max-width: none;
}

@media (min-width: 1024px) and (max-width: 1260px) {
  .headerSearch {
    /* width: 55%; */
  }
  .headerSearch .search-redesign,
  .headerSearch .search-redesign > form {
    width: 100%;
  }
  .headerSearch .main_container .selectField {
    width: 110px;
  }
  div #date-picker-inline {
    width: 50px;
  }
  .selectBox .selectContainer {
    margin-left: 0;
  }
}

.cusSelect .search-wrapper.searchWrapper {
  display: flex !important;
  flex-direction: column-reverse !important;
  gap: 15px !important;
  padding: 0;
  margin-bottom: 10px;
}

.cusSelect .search-wrapper.searchWrapper .chip {
  padding: 10px 16px;
  background: #f9fafb !important;
  border-radius: 8px;
  margin-top: 0;
  color: #344054;
  font-size: 14px;
  justify-content: space-between;
}

.cusSelect.hidechip .chip {
  display: none;
}
