 
.StepFormMAinDiv label {
    font-size: 18px;
}

.Maindiv>* {
    width: 100%;
}

.Maindiv {
    flex-wrap: wrap;
}

.Maindiv .firstNAme {
    margin-bottom: 30px;
}

.StepFormMAinDiv input {
    background: #fff;
    border-color: #8c52fe;
    width: 100%;
    font-size: 16px;
    padding-top: 0;
    height: 50px;
}
[class*=" AuthenticationPage_tabs"] > div > a span {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1.1px;
}
.SignUpStepForm  .NamedLink_active {
    color: #8c52fe;border-color: #8c52fe;
}
.SignUpStepForm button {
    font-size: 18px;
}
.StepFormMAinDiv>div {
    width: 100%;
}
.usertype .MuiInput-formControl {
    height: 50px;
    /* border-bottom: 1px solid #906efb; */
}
.usertype .MuiInput-formControl em {
    font-style: normal;
    font-size: 16px;
}
.SignUpStepForm .search-wrapper.searchWrapper input#search_input {
    background: #fff;
    border: none;
    text-align: left;
    font-weight: 400;
    height: 50px;
    border-bottom: 2px solid #8c52fe;
    border-radius: 0;
    padding: 0;
}
.SignUpStepForm .search-wrapper.searchWrapper input#search_input:-webkit-autofill,
.SignUpStepForm .search-wrapper.searchWrapper input#search_input:-webkit-autofill:hover, 
.SignUpStepForm .search-wrapper.searchWrapper input#search_input:-webkit-autofill:focus, 
.SignUpStepForm .search-wrapper.searchWrapper input#search_input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #fff inset;
}
.SignUpStepForm [class*="SignupForm_bottomWrapper"] {
    margin-top: 10px;
}
.SignUpStepForm [class*="SignupForm_bottomWrapper"] p span{color: #000;}
.SignUpStepForm [class*="SignupForm_bottomWrapper"] p [role="button"] span {
    color: #8c52fe !important;
    text-decoration: underline;
    text-underline-offset: 2px;
}
