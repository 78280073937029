 
.customtab_steper .steper_main_edit_new form {
    max-width: 700px;
    margin: 0 auto;
    background: #f1f1f1;
    padding: 20px 30px;
    border-radius: 10px;
    }

.customtab_steper .steper_main_edit_new form .d_flex {
    flex-wrap: wrap;
}

.customtab_steper .steper_main_edit_new form .d_flex>div {
    max-width: 100%;
    flex: 100%;
}

.customtab_steper .steper_main_edit_new form .Select_Services {
    font-size: 24px;
    line-height: normal;
    text-align: center;
} 
.customtab_steper .search-wrapper.searchWrapper input{
    background: #fff;    text-align: left;
    color: #000;font-size: 18px;
    margin-top: 25px;
}
.customtab_steper .search-wrapper.searchWrapper input::-webkit-input-placeholder { /* Edge */
    color: #000;
  }
  
  .customtab_steper .search-wrapper.searchWrapper input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000;
  }
  
  .customtab_steper .search-wrapper.searchWrapper input::placeholder {
    color: #000;
  }
  .customtab_steper button {
    font-size: 18px;
}