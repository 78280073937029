textarea.send_message_text_area {
  padding: 10px;
  height: 100px;
}

ul.activatifeed_cus {
  background: #f7f3ff;
  padding: 20px;
  border-radius: 5px;
  min-height: 655px;
}

p.other_message_text {
  background: #fff;
  box-shadow: none;
}

p.own_message_third:after,
p.other_message_text:after {
  content: '';
  width: 20px;
  height: 12px;
  display: block;
  background-image: url('https://support.villagehymns.com/static/icons/chat.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  bottom: -6px;
  right: -9px;
  filter: invert(56%) sepia(94%) saturate(5788%) hue-rotate(243deg) brightness(102%) contrast(101%);
  z-index: -1;
}

textarea {
  box-sizing: border-box;
}

form textarea.send_message_text_area:hover,
form textarea.send_message_text_area {
  border: 1px solid #f3f3f3;
}

p.own_message_third,
p.other_message_text {
  position: relative;
  z-index: 1;
}

p.other_message_text:after {
  left: -11px;
  right: auto;
  transform: scaleX(-1);
  filter: brightness(0) invert(1);
  z-index: 0;
}

.send_message_main {
  margin: 25px 0 0 0;
  display: flex;
  width: 100%;
  background: #f1f1f1;
  gap: 20px;
  padding: 10px;
}

form textarea.send_message_text_area {
  background-color: #fff;
  box-sizing: border-box;
  height: 60px !important;
}

form textarea.send_message_text_area::-webkit-scrollbar{
  display: none;
}

.send_message_main a {
  max-width: 100px;
  display: block;
  flex: 1;
}

.send_message_main button,
.send_message_main button:hover {
  display: block;
  min-width: auto;
  border-radius: 5px;
  background: #8c52ff;
  color: #fff;
  width: 100%;
}

.send_message_error_cus:empty {
  display: none;
}

.tranaction_messae_inner {
  flex-basis: calc(100% - 409px);
  max-width: calc(100% - 409px);
  padding-right: 20px;
}

span.answer:empty {
  display: none;
}

.send_message_main [class*='FieldTextInput_root'] {
  width: 100%;
  max-width: calc(100% - 260px);
  flex: 1;
}

.send_message_main button {
  margin: 0;
}

.send_message_main {
  align-items: center;
}
/* 26-05-2022 */
@media (max-width: 1024px) {
  .call-button {
    padding: 10px;
  }
  .call-button svg {
    margin-right: 0;
  }

  .send_message_main [class*='FieldTextInput_root'] {
    max-width: calc(100% - 150px);
  }
}

/* 26-05-2022 */
@media (max-width: 991px) {
  .tranaction_messae_inner {
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    padding: 0 15px;
  }

  .MuiBox-root-4 {
    padding: 20px 20px 0;
  }

  [class*='TransactionPanel_bookingDetailsMobile'] {
    margin-top: 0;
  }

  .send_message_text_area {
    max-width: 100%;
    width: 100%;
  }

  .send_message_main .call-button {
    margin: 0 !important;
  }

  .send_message_main {
    max-width: calc(100% - 48px);
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .ques {
    font-size: 16px;
  }

  .send_message_main {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .send_message_main [class*='FieldTextInput_root'] {
    max-width: 100%;
    flex: 100%;
    flex: 4 1;
  }

  .send_message_main > * {
    flex: 1;
    max-width: 100%;
    width: 100%;
  }

  .send_message_main button {
    margin-top: 0;
  }

  p.own_message_third:after,
  p.other_message_text:after {
    display: none;
  }
  .submit_container_cls button {
    max-width: inherit;
    min-width: auto;
    width: auto;
  }

  .submit_container_cls {
    justify-content: space-between;
    align-items: center;
  }

  .send_message_main {
    max-width: 100%;
    background: #fff;
    border-top: 1px solid #eee;
  }

  form textarea.send_message_text_area {
    border: none;
  }

  form textarea.send_message_text_area::-webkit-input-placeholder {
    /* Edge */
    color: #d3d4d5;
    font-weight: normal;
  }

  form textarea.send_message_text_area:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #d3d4d5;
    font-weight: normal;
  }

  form textarea.send_message_text_area::placeholder {
    color: #d3d4d5;
    font-weight: normal;
  }

  .submit_container_cls {
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
  }

  /* .send_message_main .call-button {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 0 88px;
  } */
  ul.activatifeed_cus {
    min-height: 500px;
    /* max-height: 500px; */
    /* overflow-y: auto; */
    padding-bottom: 180px;
  }
}

@media (max-width: 767px) {
  .fead_main_sec_sec {
    margin: 20px auto;
    max-height: 60vh;
    /* overflow: auto; */
    padding-bottom: 20px;
  }
  .fead_main_sec_sec::-webkit-scrollbar {
    display: none;
  }

  .fead_main_sec_sec > h3 {
    margin-top: 20px;
    text-align: center;
  }
  .send_message_main {
    /* margin: 0 auto; */
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: 0 !important;
  }
  .submit_container_cls .call-button svg {
    margin: 0;
  }

  .send_message_main a {
    max-width: 50px;
  }
  .send_message_main .submit_container_cls {
    padding: 0;
  }
  [class*='TransactionPanel_bookingDetailsMobile'] {
    margin-top: 20px;
  }
  .send_message_main .submit_container_cls > button {
    max-width: 50px;
  }
}


.action_button_space{
  transform: translateY(-100%) !important;
}