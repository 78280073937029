.customtab_steper .mainServicesform {
    padding-left: 50px;
    padding-right: 0;
}

.customtab_steper .mainServicesform fieldset {
    width: 100%;
    flex: 100%;
}

.EditTabcointainer.customtab_steper .mainServicesform form button {
    margin: 0;
}