@media (min-width: 1200px) {
  div.custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    min-height: 0 !important;
    margin: 0 !important;
  }

  .topbar_search_home.custom_home_ser div#multiselectContainerReact {
    height: 70px;
    top: -2px;
  }

  .search-wrapper.searchWrapper input {
    min-height: auto;
  }

  div.custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    min-height: 0 !important;
    margin: 0 !important;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .topbar_search_home.custom_home_ser div#multiselectContainerReact {
    height: 72px;
    top: 0;
    background: #fff;
    border-radius: 6px;
    border-bottom: 2px solid #8c52ff;
  }

  .SectionHero_heroContent__33KfV .TopbarSearchFormHome_servicecss__UiQ_Z {
    border-radius: 6px;
    border-bottom: 2px solid #8c52ff;
  }
}

@media (max-width: 767px) {
  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    width: 100% !important;
  }

  .custom_home_ser {
    margin-bottom: 10px;
    min-height: 80px;
    overflow: visible;
  }

  .button_container form .d_flex {
    flex-direction: column;
    width: 100%;
  }

  /* .button_container form .d_flex .TopbarSearchFormHome_mainsearchdiv__SMiwp {
    width: 100%;
  } */

  .button_container form .d_flex:nth-child(2) > div {
    height: 55px;
  }

  .LayoutSingleColumn_layoutWrapperMain__zOvja .SectionHero_heroContent__33KfV h1 span {
    width: 100%;
    display: block;
    line-height: 1.3;
  }

  .button_container form .d_flex .topbar_search_home {
    position: relative;
  }

  .button_container form .d_flex .topbar_search_home svg {
    position: absolute;
    top: 28px;
    right: 18px;
    left: auto;
  }

  .button_container form .d_flex .MuiBox-root .MuiGrid-root.MuiGrid-container .MuiFormControl-root {
    width: 100%;
  }

  .button_container form .d_flex .MuiBox-root .MuiGrid-root.MuiGrid-container {
    width: 100%;
    justify-content: space-between;
    max-width: 100%;
  }

  body
    .button_container
    form
    .d_flex
    .MuiBox-root
    .MuiGrid-root.MuiGrid-container
    .MuiInputBase-root.MuiInput-root
    input {
    text-align: left !important;
    font-size: 17px;
  }

  #date-picker-inline {
    text-align: left !important;
    padding-left: 10px;
  }

  .search-wrapper.searchWrapper {
    border: 0;
  }

  .search-wrapper.searchWrapper input {
    min-height: 61px;
    padding: 0 10px;
  }

  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    top: 0;
    position: relative;
    background: #fff;
    min-width: 220px;
    width: 230px;
  }

  .custom_home_ser .multiselect-container {
    padding: 0;
  }

  .search-wrapper.searchWrapper span.chip.false {
    background: #906efa;
  }

  .highlightOption {
    background: #906efa;
    color: #fff;
  }

  .multiSelectContainer li:hover {
    background: #906efa;
    color: #fff;
    cursor: pointer;
  }

  .topbar_search_home.custom_home_ser > div {
    width: 100% !important;
  }

  .topbar_search_home.custom_home_ser {
    margin-bottom: 10px;
    min-height: 80px;
    overflow: visible !important;
  }

  .search-wrapper.searchWrapper input {
    min-height: 42px;
  }

  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    border-bottom: 2px solid #8c52ff;
    border-radius: 6px;
  }
}

.custom_home_ser div {
  width: 100%;
}

.custom_home_ser {
  position: relative;
}

@media (min-width: 1200px) {
  .topbar_search_hero h1 {
    font-size: 48px;
    width: 70%;
    max-width: 100%;
    text-shadow: 0px 0px 10px black;

  }

  .w_100.topbar_search_home {
    margin-left: 0;
  }

  h1 span {
    display: block;
  }
}

@media (max-width: 767px) {
  .main_container .button_container {
    padding: 0;
    margin: 0 auto;
    max-width: 100%;
  }

  .topbar_search_hero h1 {
    line-height: 1.2;
    font-size: 32px;
  }
}

.TopbarDesktop_root__1JhZ0 .contactus {
  color: black;
}

.search-redesign {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
}

#search_input {
  margin: 0;
}
