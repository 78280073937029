@import '../../styles/propertySets.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
  width: 50%;
}

.heroButton {
  @apply --marketplaceButtonStyles;
  background-color: #8c52ff;
  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}

.mainserch {
  margin-left: 15px;
  display: flex;
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH1FontStyles;

  /* Layout */
  margin: 0 18px 0 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  height: var(--topbarHeightDesktop);
  border: none;
  padding-top: 3px;
  display: none !important;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.desktopInput {
  flex-grow: 1;
  /* font-size: 19px !important; */
  /* Font */
  @apply --marketplaceH4FontStyles;
  height: var(--topbarHeightDesktop);
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  &:hover,
  &:focus {
    border-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100vh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
}

.customform {
  display: flex;
}

.LocationAutocompleteInput {
  width: 100%;
  /* max-width: 280px; */
  background: #fff;
  border-radius: 5px;
  position: relative;
  font-size: 19px;
}

/* form.customform .LocationAutocompleteInput_root__3YILC.TopbarSearchFormHome_desktopInputRoot__2ALsP .TopbarSearchFormHome_desktopIcon__3cBdP {
  position: absolute;
  right: 11px;
  transform: rotate(275deg);
  background: transparent;
} */

form.customform
  .LocationAutocompleteInput_root__3YILC.TopbarSearchFormHome_desktopInputRoot__2ALsP
  input {
  padding: 0 37px 0 20px;
  font-size: 16px;
}

.servicecss {
  width: 100%;
  /* max-width: 340px; */
  background: #fff;
  border-radius: 5px;
  padding: 0 20px;
  font-size: 16px;
  position: relative;
  color: #ccc;
  /* margin-left: 15px; */
}

.mainservicediv {
  position: relative;
  display: flex;
}

.mainsearchdiv {
  position: relative;
  /* margin-left: 15px; */
}

@media (max-width: 1024px) {
  .mainsearchdiv > div {
    padding: 0;
  }
}

.MuiBox-root-1 {
  width: 48%;
}

.serchsvg {
  width: 100%;
  max-width: 17px;
  height: 17px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  pointer-events: none;
}

.selectsvg {
  position: absolute;
  max-width: 17px;
  top: 50%;
  transform: translateY(-50%);
  left: 329px;
  pointer-events: none;
}

.heroButton1 {
  margin-left: 15px;
  border-radius: 5px;
  font-size: 22px;
}

.select_date {
  margin-left: 15px;
}

@media (max-width: 991px) {
  .LocationAutocompleteInput {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .main_container {
    margin-top: 15px;
  }

  .mainserch,
  .heroButton1,
  .select_date {
    margin: 0px;
  }

  .serchsvg {
    max-width: 15px;
    height: 15px;
    top: 31px;
  }

  .mainserch,
  .select_date {
    width: 48%;
  }

  .customform {
    flex-wrap: wrap;
  }

  .heroButton1 {
    font-size: 20px;
    height: 55px;
    min-height: 55px;
  }

  .desktopInput {
    margin-top: 0;
    margin-bottom: 0;
    height: 55px;
  }

  .LocationAutocompleteInput {
    height: 55px;
    margin-bottom: 15px;
  }

  .servicecss {
    height: 55px;
    font-size: 16px;
  }

  .mainsearchdiv {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }

  .main_homepage_section {
    position: relative;
  }

  .mainserch {
    width: 100%;
    position: relative;
  }

  .mainserch svg {
    position: absolute;
    max-width: 17px;
    top: 28px;
    transform: translateY(-50%);
    left: auto;
    right: 15px;
    opacity: 0;
  }

  #date-picker-inline {
    text-align: left !important;
  }
}

@media (max-width: 414px) {
  .selectsvg {
    position: absolute;
    max-width: 15px;
    top: 27px;
    height: 15px;
    transform: translateY(-50%);
    left: 130px;
    pointer-events: none;
  }
}

.searchButtonAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
