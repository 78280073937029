.paginationContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-top: 1px solid var(--matterColorNegative);
  padding-top: 20px;
  @media(max-width:600px){
    flex-direction:column;
    align-items:center;
  }
}

.paginationLink {
  color: #667085;
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.paginationLink.active {
  background-color: #f9fafb;
  border-radius: 8px;
  color: #1d2939;
}
