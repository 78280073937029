.CandleDialoge div.MuiDialog-paperWidthSm {
    max-width: 580px;
    width: 100%;
    min-height: 350px;
    padding: 30px;
    background: #e2e2e2;
}

.CandleDialoge button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.cancel_button.MuiButton-containedSizeLarge.MuiButton-sizeLarge.Mui-disabled.Mui-disabled {
    color: #fff;
}

.CandleDialoge div.MuiDialog-paperWidthSm .descriptions label,
.Heading {
    font-size: 26px;
    text-align: center;
    line-height: 1.2;
    padding: 0;
}

.CandleDialoge .MuiDialog-paperWidthSm button {
    background-color: #8c52ff;
    color: #fff;
    max-width: 200px;
    width: 100%;
    height: 60px;
    border-radius: 6px;
}

.CandleDialoge .MuiDialog-paperWidthSm button:hover {
    background-color: #8c52ff;
}

.CandleDialoge div.MuiDialog-paperWidthSm .MuiBox-root {
    width: 100%;
    text-align: center;
}

.CandleDialoge div.MuiDialog-paperWidthSm input[type='text'] {
    padding: 10px;
    border-radius: 12px;
    line-height: normal;
    border: none;
    background: #Fff;
    height: 70px;
    margin-top: 40px;
}

.CandleDialoge .Heading {
    padding-bottom: 15px
}

.CandleDialoge .MuiDialogContent-root:first-child {
    flex: unset
}

.MuiDialogActions-root {
    width: 100%;
}

.CandleDialoge div.MuiDialog-paperWidthSm {
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.CandleDialoge .MuiDialogContent-root:first-child {
    width: 100%;
}

.CandleDialoge .MuiDialogTitle-root .MuiIconButton-root.icon {
    position: absolute;
    right: 0;
    max-width: 50px;
    height: 50px;
    top: 0;
}

.CandleDialoge .MuiDialogContent-root {
    padding: 0;
    overflow: inherit;
}

.CandleDialoge .FieldTextInput_root__2ODuL textarea {
    width: 100%;
    height: 100px;
    background: #fff;
    border-radius: 10px;
    border: none;
    resize: none;
    margin-top: 20px;
    overflow-y: auto;
}

.CandleDialoge .MuiPaper-root.MuiDialog-paper>.MuiDialogTitle-root {
    padding: 0;
}

@media (max-width: 767px) {
    .CandleDialoge div.MuiDialog-paperWidthSm {
        padding: 20px;
    }

    .CandleDialoge .MuiDialogContent-root:first-child {
        overflow: visible;
        padding: 0;
    }

    .CandleDialoge div.MuiDialog-paperWidthSm .MuiBox-root {
        line-height: 1.5;
        padding-top: 10px;
    }

    .CandleDialoge div.MuiDialog-paperWidthSm .descriptions label,
    .Heading {
        font-size: 20px;
    }

    .CandleDialoge button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.cancel_button.MuiButton-containedSizeLarge.MuiButton-sizeLarge.Mui-disabled.Mui-disabled {
        color: #fff;
    }

    .CandleDialoge .MuiDialogTitle-root .MuiIconButton-root.icon {
        max-width: 40px;
        height: 40px;
    }
}