@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.servicesstyle {
  font-weight: var(--fontWeightSemiBold);
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  text-align: center;
  text-decoration: none;
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}

/* @media (max-width: 767px) {
  .MuiButton-containedPrimary {
    margin-top: 20px;
    margin-right: 20px;
  }
} */
/* Sort css */

/* 
.selctcity {
  max-width: 300px;
  position: absolute;
  top: 123px;
  background: #fff;
  z-index: 1;
  box-shadow: 0 0 5px #ddd;
  left: 280px;
  padding: 20px;
}

.searchWrapper {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 0 10px !important;
}

search_input {
  border-bottom: 1px solid var(--marketplaceColor) !important;
  font-size: 15px;
} 


.searchWrapper {
  border: 0;
  padding: 0;
  min-height: 20px !important;
}

.button {
  border: 0;
  color: var(--marketplaceColor);
  width: 100%;
  margin-left: auto;
  margin-right: 0;
  text-align: end;
}

.FilterPopup_root__1Xfkn {
  border: 0;
}

.FilterPopup_root__1Xfkn1:focus {
  outline: none !important;
  border: 0 !important;
}

.FilterPopup_root__1Xfkn1 {
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  min-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
}

.FilterPopup_root__1Xfkn1:hover {
    border: 1px solid #ccc;
    box-shadow: 0 0 6px #dddd;
} */

.adjustFromTop {
  padding-top: calc(var(--topbarHeightDesktop) + 30px);
  margin: 0 100px;
  @media(max-width:600px){
    padding-top: calc(var(--topbarHeightDesktop) - 20px);
    margin:0 20px;
  }
}

.searchFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
