.modalContentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalContent {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  width: 50%;
  max-height: 90%;
  overflow: auto;
  @media (max-width: 600px) {
    width: 90%;
  }
}

.title {
  color: #101828;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  @media (max-width: 600px) {
    letter-spacing: 0;
    line-height: 38px;
  }
}

.desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #344054;
}

.headerLogo {
  height: 40px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.actionImg {
  max-width: 100%;
  border-radius: 10px;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.button {
  width: 100%;
  border-radius: 8px;
  color: #344054;
  font-size: 16px;
  margin-bottom: 10px;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  padding: 10px 0;
}

.button.action {
  background-color: #7f56d9;
  border: none;
  color: white;
}
