@media (max-width: 767px) {
  .custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
    width: 100% !important;
  }

  .custom_home_ser {
    margin-bottom: 10px;
    min-height: auto;
    overflow: visible;
  }


  .button_container form .d_flex {
    flex-direction: column;
    width: 100%;
  }

  /* .button_container form .d_flex .TopbarSearchFormHome_mainsearchdiv__SMiwp {
    width: 100%;
  } */

  .button_container form .d_flex:nth-child(2)>div {
    width: 100%;
    margin-bottom: 15px;
  }


  .LayoutSingleColumn_layoutWrapperMain__zOvja .SectionHero_heroContent__33KfV h1 span {
    width: 100%;
    display: block;
    line-height: 1.3;
  }

  .button_container form .d_flex .w_100.topbar_search_home svg {
    position: absolute;
    top: 29px;
    right: 19px !important;
    left: auto;
  }

  .button_container form .d_flex .topbar_search_home {
    position: relative;
  }

  .button_container form .d_flex .topbar_search_home svg {
    position: absolute;
    top: 29px;
    right: 18px;
    left: auto;
  }

  .button_container form .d_flex .MuiBox-root .MuiGrid-root.MuiGrid-container .MuiFormControl-root {
    width: 100%;
  }

  .button_container form .d_flex .MuiBox-root .MuiGrid-root.MuiGrid-container {
    width: 100%;
    justify-content: space-between;
    max-width: 100%;
  }

  body .button_container form .d_flex .MuiBox-root .MuiGrid-root.MuiGrid-container .MuiInputBase-root.MuiInput-root input {
    text-align: left !important;
    font-size: 17px;
  }

  #date-picker-inline {
    text-align: left !important;
    padding-left: 0;
  }
  .button_container .main_homepage_section, .main_homepage_section,.main_container .button_container form .d_flex:nth-child(2)>div {
    padding: 0 0 !important;
}
.main_container {
  padding: 0 15px;
}
.button_container form button {
  margin-bottom: 15px !important;border-radius: 5px !important;font-size: 16px;
}
.button_container form .d_flex:nth-child(2)>div,.main_container_PopupMobile .custom_home_ser .multiselect-container .search-wrapper.searchWrapper,.main_container_PopupMobile .main_homepage_section .topbar_search_home select,.button_container form .d_flex .MuiBox-root .MuiGrid-root.MuiGrid-container {
  border-radius: 0 !important;
}
.custom_home_ser .search-wrapper.searchWrapper input {
  height: auto;
  border: none;
  text-align: left;
}
}

.search-wrapper.searchWrapper {
  border: 0;
  /* display: flex; */
  /* flex-direction: column-reverse; */
  /* justify-content: space-between; */
  /* padding: 10px 14px; */

}

.search-wrapper.searchWrapper input {
  min-height: 44px;
  padding: 0 10px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
}

.custom_home_ser .multiselect-container .search-wrapper.searchWrapper {
  top: 0;
  position: relative;
  background: #fff;
  min-width: 220px;
  width: 230px;
}

.custom_home_ser .multiselect-container {
  padding: 0;
}

.search-wrapper.searchWrapper span.chip.false {
  background: #906efa;
  /* color: black; */
  /* width: 100%; */
/* display: flex; */
/* justify-content: space-between; */
/* padding: 10px 14px; */

}



.highlightOption {
  background: #906efa;
  color: #fff;
}

.multiSelectContainer li:hover {
  background: #906efa;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 767px) {
  .search-wrapper.searchWrapper input {
    min-height: 40px;
  }

  .custom_home_ser>div {
    height: 55px;
  }

  .w_100.topbar_search_home {
    height: auto;
  }
}