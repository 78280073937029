/* .custom_listing_css .custom_listing_css_child {
    flex-basis: 100%;
    margin-right: 0 !important;
    box-shadow: 0 0 5px #ddd;
    padding: 30px;
} */
.custom_listing_css .custom_main .custom_price_unit {
  margin-bottom: 10px;
}

.custom_listing_css .custom_main button.avalibility_button {
  border: 0;
  width: 100%;
  max-width: 330px;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 30px !important;
  font-weight: 600;
}

.custom_listing_css .custom_main .location_icon,
.custom_listing_css .custom_main .time_cons,
.custom_listing_css .custom_main .review_details {
  display: flex;
  /* align-items: center; */
  color: #000;
}

.custom_listing_css .custom_main .location_icon svg,
.custom_listing_css .custom_main .time_cons svg {
  color: #927afd;
  margin-right: 10px;
}

.custom_listing_css .custom_main .review_details svg {
  color: #927afd;
}

.custom_listing_css .custom_main .review_details svg:last-child {
  margin-right: 10px;
}

.custom_main {
  align-items: flex-start;
  justify-content: space-between;
}

.custom_main .ViewListion_class_div button.button_listing_classs {
  padding: 15px 30px;
  border: 0;
  width: 100%;
  margin-left: 0 !important;
}

.custom_listing_css div.custom_listing_css_child {
  flex-basis: 100%;
  margin-right: 0 !important;
  padding: 30px;
  display: flex !important;
  flex-direction: initial;
  justify-content: space-between;
  /* align-items: center; */
  margin: 10px 0;
}

.threeToTwoWrapper {
  flex: 0 0 30%;
  max-width: 30%;
}

.class_name_inner {
  width: 37.5vw;
  display: none;
}

.custom_class_main_sec {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  width: 100%;
}

.custom_listing_css .custom_listing_css_child .custom_main {
  flex: calc(100% - 30%);
  max-width: calc(100% - 30%);
  padding-left: 30px;
}

.custom_listing_css {
  max-width: 1000px;
  margin: 0 auto;
}

.custom_listing_css .custom_listing_css_child .custom_main .custom_main_child_1 {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  width: 100%;
}

.custom_listing_css .custom_listing_css_child .custom_main .ViewListion_class_div {
  flex: calc(100% - 70%);
  max-width: calc(100% - 70%);
}

body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

/* 01-05-2022 */
.custom_class_main_sec .advanceSearch {
  position: sticky;
  top: 90px;
}

/* 08-05-2022 */
.custom_main {
  flex-wrap: wrap;
}

.custom_listing_css .custom_listing_css_child .custom_main .ViewListion_class_div button.button_listing_classs {
  margin-top: 0;
  max-width: 200px;
}

.music_btn_name_listing_card {
  line-height: normal;
  padding: 5px 10px 5px 5px;
}

.custom_listing_css .custom_listing_css_child .custom_main .ViewListion_class_div {
  margin-left: auto;
  width: 100%;
  flex: 100%;
  max-width: 100%; 
}
/* 29-05-2022 */
.custom_listing_css .custom_listing_css_child .custom_main .custom_main_child_1 .star_icon_custom{
  position: absolute;
  right: 0;
  top: 0;
}
.location_icon {
  align-items: center;
}

/* end of 29-05-2022 */
@media (max-width: 1365px) {
  .custom_main .ViewListion_class_div button.button_listing_classs {
    padding: 10px 28px;
  }
}

@media (max-width: 1199px) {
  .custom_listing_css .custom_main button.avalibility_button {
    padding: 5px;
    font-size: 14px;
  }
  .sort_component .makeStyles-buttonCss-3 {
    margin-right: 65px;
}
}

@media (max-width: 1023px) {
  .custom_main .ViewListion_class_div button.button_listing_classs {
    padding: 6px 9px;
    font-size: 14px;
  }
   .sort_component .makeStyles-buttonCss-3 {
    margin-right: 60px;
}
}

@media (max-width: 991px) {
  .custom_listing_css .custom_listing_css_child {
    padding: 20px;
  }

  .custom_main {
    flex-direction: column;
  }

  .custom_main .ViewListion_class_div button.button_listing_classs {
    margin-top: 20px !important;
  }
}

.custom_title {
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 10px;
}

/* 16-05-2022 */
.Favorites {
  max-width: 200px;
  position: fixed;
  right: 0;
  background: #fff;
  top: 70px;
  height: 100vh;
  width: 100%;
  border-left: 1px solid #ddd; 
}

a.favourite_section {
  text-decoration: none;
}

a.favourite_section span {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'poppins';
  letter-spacing: 0.8px;
}



.MuiAvatar-colorDefault {
  background-color: #8c52ff;
}

.Favorites h6.MuiTypography-root {
  font-weight: 700;
  font-family: 'poppins';
  letter-spacing: normal;
  padding: 20px;
  background: #fff;
  text-transform: capitalize;
}

.Favorites img {
  border-radius: 25px;
  object-fit: cover;
}

.MuiAvatar-root.MuiAvatar-circular {
  position: relative;
  /* overflow: visible; */
}

/* 17-05-2022 */
[class*="ListingPage_messageButtonMain"] {
  max-width: calc(100% - 200px);
  flex: calc(100% - 200px);
  text-align: right;
}

[class*="ListingPage_messageButtonMain"] button {
  margin-right: 0;
  margin-left: auto;
}

.heading_name {
  display: flex;
  align-items: center;
}

[class*="ListingPage_contentContainer"] [class*="ListingPage_mainContent"] {
  margin-top: 70px;
}

.star_icon_custom {
  margin-left: 10px;
  padding: 3px;
}

ul.MuiList-root.MuiList-padding a.favourite_section:not(:last-child) {
  border-bottom: 1px dotted #ddd;
  display: block;
}
/* 29-05-2022 */
.mainContainer { 
  width: 100%;
}
.mainContainer>.custom_listing_css_child{
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px 4px rgb(0 0 0 / 25%);
  width: 100%;
}
/* 05-06-2022 */
.searchListingsPanel_new .custom_listing_css {
  width: calc(100% - 200px);
  margin-left: 0;
  flex: calc(100% - 200px);
  max-width: 100%;
} 


@media (min-width: 1025px) {
  
  .custom_listing_css .custom_listing_css_child .custom_main {
    position: relative;
}

.custom_listing_css .custom_listing_css_child .custom_main a.custom_listing_css_child {
    position: absolute;
    right: 0;
    max-width: 200px;
    width: 100%;
    bottom: 0;
}
.custom_image img{
  height: 100%;
    object-fit: cover;
}
.custom_image>div {
  border-radius: 5px;
  overflow: hidden;
}
}

@media screen and (max-width: 756px) {
  .w_100_m {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 767px) {
  /* .threeToTwoWrapper {
    flex: 0 0 100%;
    max-width: 100%;
  } */

  .custom_listing_css .custom_listing_css_child {
    flex-direction: column;
  }


  .custom_listing_css .custom_listing_css_child .custom_main .custom_main_child_1 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100%;
  }

  .custom_listing_css .custom_listing_css_child .custom_main .ViewListion_class_div {
    flex: 100%;
    max-width: 100%;
    padding-left: 0;
  }

  .custom_listing_css {
    max-width: calc(100% - 30px);
    margin: 0 auto;
    padding: 0;
  }

  .custom_class_main_sec .advanceSearch {
    flex: 100%;
    max-width: 100%;
  }

  .custom_listing_css .SearchResultsPanel_listingCard__3hmi9 .custom_main {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .custom_class_main_sec .advanceSearch {
    position: unset;
  }

  .custom_listing_css .custom_listing_css_child .custom_main .ViewListion_class_div button.button_listing_classs {
    max-width: 100%;
  }

  .custome_price {
    margin-top: 10px;
  }

  div.button {
    position: sticky;
    bottom: 5px;
    max-width: calc(100% - 30px);
    margin: 0 auto;
  }

  div.button button.wish_button {
    width: 100%;
    background: #fff;
    border: 2px solid;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .Favorites {
    top: 0;
    box-shadow: -3px 0px 6px 0px rgb(0 0 0 / 24%);
    z-index: 99;
  }

  .cross_icons {
    right: 5px;
    background: #8c52ff;
    position: absolute;
    padding: 5px;
    top: 5px;
    z-index: 99;

  }

  .cross_icons svg {
    fill: #fff;
  }
 
}

@media screen and (max-width: 767px) {
  .m_0_768 {
    margin: 0px !important;
  }

  .MuiFormControl-marginNormal {
    margin-top: 4px !important;
    margin-bottom: 4px !important;
  }

  .searchListingsPanel_new .custom_listing_css .custom_listing_css_child .ListingCard_threeToTwoWrapper__27YXq {
    flex: 0 0 24%;
    max-width: 24%;
  }

  .threeToTwoWrapper {
    flex: 0 0 140px;
    max-width: 140px;
  }

  .custom_listing_css .custom_listing_css_child .custom_main {
    flex: calc(100% - 140px) 1;
    max-width: calc(100% - 140px);
    padding-left: 10px;
  }

  .custom_title h2 {
    font-size: 14px;
    margin: 0;
  }

  .custom_title {
    margin-bottom: 5px;
  }

  .star_icon_review {
    font-size: 13px;
    margin-bottom: 5px;
  }

  span.review_qty {
    margin-right: 5px;
  }

  .star_icon_review svg {
    width: 16px;
  }

  .custom_listing_css .custom_main .location_icon,
  .custom_listing_css .custom_main .time_cons,
  .custom_listing_css .custom_main .review_details {
    color: #676d73;
  }

  .ListingPage_reviewsHeading__3zmuY,
  .searchListingsPanel_new .custom_listing_css .custom_main_child_1 .location_icon,
  .custom_listing_css .custom_main .time_cons,
  .custome_price {
    align-items: flex-start;
  }

  span.csnt-bold {
    font-weight: 700;
    margin-left: 2px;
    color: #2f3033;
  }

  div.searchListingsPanel_new .custom_listing_css .custom_listing_css_child {
    flex-wrap: wrap;
    justify-content: center;
  }

  .ViewListion_class_div.w_100_m button {
    margin: 0 0 20px !important;
    width: 100%;
    border: none;
    height: 50px;
  }

  .review_details h2 {
    margin-top: 0;
    text-align: center;
    font-size: 14px;
  }

  .custom_image {
    padding-bottom: 100%;
    background: transparent;
  }
 
  .custom_image img {
    height: 100%;
    max-height: 93px;
    object-fit: cover;
    border-radius: 10px;
  }

  ul.MuiList-root.MuiList-padding {
    padding-top: 40px;
  }
  .sort_component .makeStyles-buttonCss-3 {
    margin-right: 15px;
    margin-bottom: 0;
  }
  a[title="Go to homepage"] {
    display: none;
}
.searchListingsPanel_new .custom_listing_css {
  max-width: calc(100% - 30px) !important;
  padding: 30px 0 0;
  width: 100%;
}
.searchListingsPanel_new .custom_listing_css .custom_listing_css_child .custom_image >div {
  top: 5px !important;
}
}

.likedProfiles{
  border: none;
  outline: none;
  display: flex;
  margin-left: auto;
  margin-bottom: 15px;
  align-items: center;
  margin-right: 1%;
}