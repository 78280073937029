.dialog_content .Heading1 {
    text-align: center;
}

.last_para {
    max-width: calc(100% - 40px);
    margin: 0 0 0 auto;
}
.Dialog_Actions button span {
    font-size: 18px;
}
.Dialog_Actions button { 
    padding: 10px 35px;
}
.dialog_content {
    padding: 20px;
}

/* media query */
@media (max-width: 767px){
    .last_para {
        max-width: 100%; 
    }
    .Dialog_Actions button {
        padding: 10px 15px;
    }
    .Dialog_Actions button span {
        font-size: 14px;
    }
}